import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { DropdownItem, PrimaryButton, useToasts } from '@library/components';
import { TextField } from '@library/components/forms/fields/text/input/field';
import { DropdownField } from '@library/components/forms/fields/selectors/dropdown/field';
import { formErrorMap } from '@library/components/forms/form-error-map';

import {
  CreateCustomerFormSchemaType,
  EnrollmentStatusEnumDefinition,
  EnrollmentStatusType,
  createCustomerSchema
} from '../schema/schemas';
import { NumberField } from '@library/components/forms/fields/number/field';

import { useInstitution } from '@/context-providers/institution';

import { useApiQuery } from '@hooks/use-api-query';
import { useApiMutation } from '@hooks/use-api-mutation';

import { CreateCustomer } from '@models/customer';

import { customerService } from '@/services/customer';
import { organizationService } from '@services/organization';
import { portfolioService } from '@services/portfolio';

const getOptInText = (value) =>
  value === 'INACTIVE' ? 'Opted out' : 'Opted in';
const AddSingleCustomer = ({
  setIsModalOpen
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [sourceOrganizationId, setSourceOrganizationId] = useState('');
  const [sourcePortfolioId, setSourcePortfolioId] = useState('');
  const [createCustomerPayload, setCreateCustomerPayload] = useState<
    CreateCustomer[]
  >([]);

  const [t] = useTranslation();
  const { sendToast } = useToasts();
  const { currency, institutionInfo, currentInstitutionId } = useInstitution();
  const queryClient = useQueryClient();

  const [isSaving, setIsSaving] = useState(false);

  const createMutation = useApiMutation(customerService.create());

  const {
    data: organizationResponse,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization
  } = useApiQuery(
    organizationService.get({
      params: {
        filter: [
          {
            key: 'source_organization_id',
            value: sourceOrganizationId,
            type: 'string'
          }
        ],
        institutionId: currentInstitutionId
      },
      enabled: sourceOrganizationId.length > 0
    })
  );

  const {
    data: portfolioResponse,
    isLoading: isLoadingPortfolio,
    isError: isErrorPortfolio
  } = useApiQuery(
    portfolioService.get({
      params: {
        filter: [
          {
            key: 'source_portfolio_id',
            value: sourcePortfolioId,
            type: 'string'
          }
        ],
        institutionId: currentInstitutionId
      },
      enabled: sourcePortfolioId.length > 0
    })
  );

  useEffect(() => {
    if (isOrganizationValid() && isPortfolioValid()) {
      createCustomer();
    }
  }, [
    organizationResponse,
    isLoadingOrganization,
    isErrorOrganization,
    portfolioResponse,
    isLoadingPortfolio,
    isErrorPortfolio
  ]);

  const createCustomer = () => {
    setIsSaving(true);

    createMutation.mutate(
      {
        payload: createCustomerPayload
      },
      {
        async onSuccess() {
          // // invalidate cache to request org list again after redirection
          await queryClient.invalidateQueries({
            queryKey: customerService.invalidationKey
          });

          sendToast({
            variant: 'variant.success',
            content: 'Customer Added, will be available in a couple of hours'
          });

          setIsModalOpen(false);
        },
        onError(error) {
          // // enabling button in case there was an error user needs to perform changes
          setIsSaving(false);

          sendToast({
            variant: 'variant.error',
            content:
              error?.message || 'Could not add Customers. Try again later'
          });
        }
      }
    );
  };

  const isOrganizationValid = () => {
    if (
      !isLoadingOrganization &&
      !isErrorOrganization &&
      organizationResponse &&
      (organizationResponse.organizations.length === 0 ||
        !organizationResponse.organizations[0].isActive)
    ) {
      const errorMessage =
        organizationResponse.organizations.length === 0
          ? 'The Source Organization ID does not exist. Please check and try again.'
          : 'The Source Organization ID is not active. Please check and try again.';

      sendToast({
        variant: 'variant.error',
        content: errorMessage
      });
      setSourceOrganizationId('');
      setIsSaving(false);
      return false;
    }

    return (
      organizationResponse &&
      organizationResponse.organizations.length > 0 &&
      organizationResponse.organizations[0].isActive
    );
  };

  const isPortfolioValid = () => {
    if (
      !isLoadingPortfolio &&
      !isErrorPortfolio &&
      portfolioResponse &&
      (portfolioResponse.portfolios.length === 0 ||
        !portfolioResponse.portfolios[0].isActive)
    ) {
      const errorMessage =
        portfolioResponse.portfolios.length === 0
          ? 'The Source Portfolio ID does not exist. Please check and try again.'
          : 'The Source Portfolio ID is not active. Please check and try again.';

      sendToast({
        variant: 'variant.error',
        content: errorMessage
      });
      setSourcePortfolioId('');
      setIsSaving(false);
      return false;
    }

    return portfolioResponse && portfolioResponse.portfolios.length > 0;
  };

  const onSubmit: SubmitHandler<CreateCustomerFormSchemaType> = (
    data
  ): void => {
    setIsSaving(true);

    const payload = [data].map((customer) => ({
      ...customer,
      enrollmentOptInStatus: customer.enrollmentOptInStatus.id,
      country: institutionInfo.country,
      operatingCurrencyCode: institutionInfo.operatingCurrencyCode
    }));

    setSourceOrganizationId(payload[0].sourceOrganizationId);
    setSourcePortfolioId(payload[0].sourcePortfolioId);

    setCreateCustomerPayload(payload);
  };

  const methods = useForm<CreateCustomerFormSchemaType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(createCustomerSchema, { errorMap: formErrorMap }),
    defaultValues: {
      sourcePortfolioId: '',
      sourceAccountId: '',
      sourceCustomerId: '',
      sourceOrganizationId: '',
      enrollmentOptInStatus: { id: 'ACTIVE' },
      lifetimeEarnedAmount: undefined
    }
  });

  const { handleSubmit } = methods;

  return (
    <div>
      <h4 className='mb-8 text-lg font-bold'>
        {t('label.addSingleTestCustomer')}
      </h4>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-2 gap-4'>
            <TextField<CreateCustomerFormSchemaType>
              name='sourceCustomerId'
              label={`${t('label.sourceCustomerId')}`}
              clearable={false}
              counter={false}
              disabled={false}
              size='size.md'
            />
            <TextField<CreateCustomerFormSchemaType>
              name='sourceAccountId'
              label={`${t('label.sourceAccountId')}`}
              clearable={false}
              counter={false}
              disabled={false}
              size='size.md'
            />
          </div>
          <div className='-mt-4 grid grid-cols-4 gap-4'>
            <TextField<CreateCustomerFormSchemaType>
              name='sourceOrganizationId'
              label={`${t('label.sourceOrganizationId')}`}
              clearable={false}
              counter={false}
              disabled={false}
              size='size.md'
            />
            <TextField<CreateCustomerFormSchemaType>
              name='sourcePortfolioId'
              label={`${t('label.sourcePortfolioId')}`}
              clearable={false}
              counter={false}
              disabled={false}
              size='size.md'
            />
            <DropdownField<
              CreateCustomerFormSchemaType,
              EnrollmentStatusEnumDefinition
            >
              name='enrollmentOptInStatus'
              label={`${t('label.enrollmentStatus')}`}
              items={EnrollmentStatusType}
              itemKey='id'
              disabled={false}
              size='size.md'
            >
              {(item) => <DropdownItem>{getOptInText(item.id)}</DropdownItem>}
            </DropdownField>
            <NumberField<CreateCustomerFormSchemaType>
              name='lifetimeEarnedAmount'
              label={`${t('label.lifetimeEarned')}`}
              disabled={false}
              size='size.md'
              currency={currency.code}
            />
          </div>
          <div className='-mt-4'>
            <PrimaryButton size='size.md' type='submit' disabled={isSaving}>
              {t('label.createSingleCustomer')}
            </PrimaryButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export { AddSingleCustomer };
