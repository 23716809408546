import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { EnabledInstitution } from '@models/institution';

const getEnabled: AdapterFunction<EnabledInstitution[]> = ({ signal }) => {
  const url = '/v1/institutions';

  const response = apiTransport.get<EnabledInstitution[]>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export { getEnabled };
