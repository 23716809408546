import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import {
  ClientSideTable,
  PrimaryButton,
  tableColumnDefs,
  TextInput,
  useToasts
} from '@/library/components';

import { useInstitution } from '@/context-providers/institution';
import { CreateCustomer } from '@/models/customer';

import { useApiMutation } from '@hooks/use-api-mutation';
import { useApiQuery } from '@hooks/use-api-query';

import { customerService } from '@/services/customer';
import { organizationService } from '@services/organization';
import { portfolioService } from '@services/portfolio';

import './PreviewCustomersModal.css';

export interface PreviewMultipleCustomersProps {
  customers: CreateCustomer[];
  setIsModalOpen: Dispatch<boolean>;
  setIsAddCustomerModalOpen: Dispatch<boolean>;
}

const PreviewCustomersModalContent = ({
  customers,
  setIsModalOpen,
  setIsAddCustomerModalOpen
}: PreviewMultipleCustomersProps) => {
  const [t] = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState<CreateCustomer[]>(
    []
  );

  const [isSaving, setIsSaving] = useState(false);
  const [sourceOrganizationIds, setSourceOrganizationIds] = useState<string[]>(
    []
  );
  const [sourcePortfolioIds, setSourcePortfolioIds] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { sendToast } = useToasts();
  const { currency, institutionInfo, currentInstitutionId } = useInstitution();

  const createMutation = useApiMutation(customerService.create());

  const filteredCustomers = useMemo(
    () =>
      customers.filter((customer) =>
        customer.sourceCustomerId
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      ),
    [customers, searchText]
  );

  const columns = [
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Source Customer ID',
      accessorKey: 'sourceCustomerId',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>{row.original.sourceCustomerId}</div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Source Account ID',
      accessorKey: 'sourceAccountId',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>{row.original.sourceAccountId}</div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Source Organization ID',
      accessorKey: 'sourceOrganizationId',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>
              <div>{row.original.sourceOrganizationId}</div>
              {!validOrganizationIds.includes(
                row.original.sourceOrganizationId
              ) &&
                !isLoadingOrganization && (
                  <p className='text-xs text-error'>
                    {t('organization.nonExisting')}
                  </p>
                )}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Source Portfolio ID',
      accessorKey: 'sourcePortfolioId',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>
              <div>{row.original.sourcePortfolioId}</div>
              {!validPortfolioIds.includes(row.original.sourcePortfolioId) &&
                !isLoadingPortfolio && (
                  <p className='text-xs text-error'>
                    {t('portfolio.nonExisting')}
                  </p>
                )}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Status',
      accessorKey: 'enrollmentOptInStatus',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>
              {row.original.enrollmentOptInStatus === 'ACTIVE'
                ? 'Opted in'
                : 'Opted out'}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<CreateCustomer>({
      header: 'Lifetime Earned Amount',
      accessorKey: 'lifetimeEarnedAmount',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>{`${currency.mark}${row.original.lifetimeEarnedAmount}`}</div>
          </>
        );
      }
    })
  ];

  // @@@@@@@@@@@@@@@@@@@@@@@
  // ORG & PORT Validation
  // @@@@@@@@@@@@@@@@@@@@@@@@@

  useEffect(() => {
    const sourceOrgIds = customers.map(
      (customer) => customer.sourceOrganizationId
    );

    const sourcePortIds = customers.map(
      (customer) => customer.sourcePortfolioId
    );

    setSourceOrganizationIds(sourceOrgIds);
    setSourcePortfolioIds(sourcePortIds);
  }, [customers]);

  const {
    data: organizationResponse,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization
  } = useApiQuery(
    organizationService.getGroup({
      params: {
        institutionId: currentInstitutionId,
        organizationIds: sourceOrganizationIds
      },
      enabled: sourceOrganizationIds.length > 0
    })
  );

  const {
    data: portfolioResponse,
    isLoading: isLoadingPortfolio,
    isError: isErrorPortfolio
  } = useApiQuery(
    portfolioService.getGroup({
      params: {
        institutionId: currentInstitutionId,
        portfolioIds: sourcePortfolioIds
      },
      enabled: sourcePortfolioIds.length > 0
    })
  );

  const validOrganizationIds = useMemo(
    () =>
      !isErrorOrganization &&
      !isLoadingOrganization &&
      organizationResponse.validOrganizations.length > 0
        ? organizationResponse.validOrganizations
        : [],
    [organizationResponse, isLoadingOrganization, isErrorOrganization]
  );

  const validPortfolioIds = useMemo(
    () =>
      !isErrorPortfolio &&
      !isLoadingPortfolio &&
      portfolioResponse.validPortfolios.length > 0
        ? portfolioResponse.validPortfolios
        : [],
    [portfolioResponse, isLoadingPortfolio, isErrorPortfolio]
  );

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const createCustomersClick = () => {
    setIsSaving(true);

    const payload = selectedCustomers
      .map((customer) => ({
        ...customer,
        country: institutionInfo.country,
        operatingCurrencyCode: institutionInfo.operatingCurrencyCode
      }))
      .filter(
        (customer) =>
          validOrganizationIds.includes(customer.sourceOrganizationId) &&
          validPortfolioIds.includes(customer.sourcePortfolioId)
      );

    createMutation.mutate(
      {
        payload
      },
      {
        async onSuccess() {
          // // invalidate cache to request org list again after redirection
          await queryClient.invalidateQueries({
            queryKey: customerService.invalidationKey
          });

          sendToast({
            variant: 'variant.success',
            content:
              'Customers Added, They will be available after a couple of hours'
          });

          setIsModalOpen(false);
          setIsAddCustomerModalOpen(false);
          setIsSaving(false);
        },
        onError(error) {
          sendToast({
            variant: 'variant.error',
            content:
              error?.message || 'Could not add Customers. Try again later'
          });
          setIsSaving(false);
        }
      }
    );
  };

  const selectCustomers = (arg) => {
    setSelectedCustomers(arg);
  };

  return (
    <div className='PreviewCustomersModal w-modal-xl p-4'>
      <div className='my-4 max-w-112'>
        <TextInput
          value={searchText}
          className='flex-1'
          onChange={setSearchText}
          decorator={<MagnifyingGlassIcon />}
          size='size.md'
          placeholder={`${t('label.searchCustomer')}`}
        />
      </div>
      <ClientSideTable
        id='customers-list'
        data={filteredCustomers || []}
        columns={columns}
        borderless={false}
        enableSelectedItems={true}
        selectedItems={selectedCustomers}
        selectedItemsKey='sourceAccountId'
        onSelectedItemsChange={selectCustomers}
        persistSelectedItemsAcrossPages={true}
        loading={isLoadingOrganization}
      />
      <div className='mt-6'>
        <PrimaryButton
          onClick={createCustomersClick}
          disabled={
            selectedCustomers.length === 0 ||
            isSaving ||
            isLoadingOrganization ||
            isLoadingPortfolio
          }
        >
          {t('label.createCustomers')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export { PreviewCustomersModalContent };
