import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  useToasts
} from '@library/components';
import { DatePicker } from '@library/components/date-picker';
import { OfferDetails } from './OfferDetails';
import { OfferPreview } from './OfferPreview';
import { DatePickerValue } from '@library/components/date-picker/single/picker';

import { useAuth } from '@/context-providers/auth';

import { useApiMutation } from '@hooks/use-api-mutation';

import { Ad, GetCampaignByIdResponse } from '@models/campaign';
import { offerService } from '@services/offer';

interface EditOfferModalContentProps {
  offer: GetCampaignByIdResponse;
  isLoading: boolean;
  isError: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditOfferModalContent = ({
  offer,
  isLoading,
  setIsModalOpen
}: EditOfferModalContentProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [endDate, setEndDate] = useState<DatePickerValue>('');

  const { isWriteUser } = useAuth();

  const [t] = useTranslation();

  const { sendToast } = useToasts();

  const queryClient = useQueryClient();

  const updateMutation = useApiMutation(offerService.update());

  const advertisement = useMemo(
    () => (offer && offer.ads && offer.ads.length > 0 ? offer.ads[0] : null),
    [offer]
  );

  useEffect(() => {
    offer.endDate && setEndDate(offer.endDate.split('T')[0]);
  }, [offer]);

  const minExpireDate = useMemo(() => {
    if (!offer.startDate) {
      return '';
    }
    const startDate = new Date(offer.startDate);
    const tomorrow = new Date(startDate);
    tomorrow.setDate(startDate.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }, [offer]);

  const handleExpireOffer = () => {
    const currentDate = new Date();
    const daysBack = 0;
    const pastDate = currentDate.getDate() - daysBack;

    currentDate.setDate(pastDate);
    // setEndDate(formatDateToString(currentDate));
    setEndDate(minExpireDate);
  };

  const handleSaveOffer = () => {
    setIsSaving(true);

    const payload = {
      accountId: offer.accountId,
      campaignId: offer.campaignId,
      endDate
    };

    updateMutation.mutate(
      {
        payload
      },
      {
        async onSuccess() {
          sendToast({
            variant: 'variant.success',
            content: `${t('label.offerUpdated')}`
          });

          setIsModalOpen(false);

          await queryClient.refetchQueries({
            queryKey: offerService.invalidationKey
          });
        },
        onError(error) {
          setIsSaving(false);

          sendToast({
            variant: 'variant.error',
            content: error?.message || `${t('portfolio.couldNotUpdateOffer')}`
          });
        }
      }
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className='h-512 w-256 overflow-visible p-4'>
        <div>
          <OfferDetails offer={offer} isLoading={isLoading} />
        </div>
        <div className='mt-10 grid grid-cols-2 gap-4'>
          <div className='px-10'>
            <h2 className='mb-6 text-lg font-bold'>{t('label.offerDates')}</h2>
            <div className='mb-4 '>
              <div className='hidden'>{minExpireDate}</div>
              <h3 className='mb-2 text-sm font-bold'>{t('label.startDate')}</h3>
              {isLoading ? (
                <div className='pulsate h-10 w-full animate-pulsate' />
              ) : (
                <DatePicker
                  id='start-date'
                  value={offer.startDate.split('T')[0]}
                  onChange={setEndDate}
                  aria-label={t('label.starDate')}
                  size='size.md'
                  disabled={true}
                />
              )}
            </div>
            <div>
              <h3 className='mb-2 text-sm font-bold'>{t('label.endDate')}</h3>
              {isLoading ? (
                <div className='pulsate h-10 w-full animate-pulsate' />
              ) : (
                <DatePicker
                  id='end-date'
                  value={endDate}
                  onChange={setEndDate}
                  aria-label={t('label.endDate')}
                  size='size.md'
                  minValue={minExpireDate}
                  disabled={!isWriteUser}
                />
              )}
            </div>
            {!isLoading && (
              <div className='mt-2'>
                <Tooltip
                  content={t('label.expireOfferMessage')}
                  placement='placement.bottom'
                >
                  <LinkButton
                    onClick={handleExpireOffer}
                    disabled={!isWriteUser}
                  >
                    {t('label.expireOffer')}
                  </LinkButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div>
            {isLoading ? (
              <div>
                <div className='pulsate mb-4 h-28 w-4/5 animate-pulsate' />
                <div className='pulsate mb-4 h-28 w-4/5 animate-pulsate' />
                <div className='pulsate mb-4 h-28 w-4/5 animate-pulsate' />
              </div>
            ) : (
              <OfferPreview advertisement={advertisement as Ad} />
            )}
          </div>
        </div>
        <div className='mt-20 flex items-center justify-between'>
          <SecondaryButton
            size='size.md'
            onClick={closeModal}
            disabled={isSaving}
          >
            {t('library.cancel')}
          </SecondaryButton>
          <div className='flex items-center'>
            {/* <div className='mr-4'>
              <SecondaryButton size='size.md' disabled={isSaving}>
                {t('label.deleteOffer')}
              </SecondaryButton>
            </div> */}
            {isWriteUser && (
              <PrimaryButton onClick={handleSaveOffer} disabled={isSaving}>
                {isSaving
                  ? t('label.updatingEllipsis')
                  : t('library.saveChanges')}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditOfferModalContent };
