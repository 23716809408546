import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstitution } from '@/context-providers/institution';

// import { RewardModelType } from '@models/campaign';

import { getRewardTypeValue } from '@/utils';

interface OfferDetailsProp {
  rewardAmount: number;
  rewardPercent: number;
  rewardType: string;
  endDate?: string;
  activationDate?: string;
  isMultiRedemption?: boolean;
}

const OfferDetails = ({
  rewardAmount,
  rewardPercent,
  rewardType,
  endDate,
  activationDate,
  isMultiRedemption
}: OfferDetailsProp) => {
  const [t] = useTranslation();
  const { currency } = useInstitution();

  const amountDisplay = useMemo(
    () =>
      rewardAmount
        ? `${currency.mark}${
            rewardAmount % 1 === 0
              ? rewardAmount
              : (Math.round(rewardAmount * 100) / 100).toFixed(2)
          }`
        : `${Math.round(rewardPercent * 100) / 100}%`,
    [rewardAmount, rewardPercent]
  );

  const rewardTypeText = useMemo(() => {
    const rewardTypeValue = getRewardTypeValue(rewardType, isMultiRedemption);

    return t(`rewardModel.${rewardTypeValue}`);
  }, [rewardType]);

  return (
    <div className='ml-10 flex w-[200px] shrink flex-col pt-2'>
      <h2 className='pb-2 text-xl font-bold text-blue-action'>
        {amountDisplay}
      </h2>
      <p className='mb-4 text-sm'>{rewardTypeText}</p>
      {activationDate && activationDate.length > 0 && (
        <p className='mb-2 text-sm font-bold'>
          {t('label.offerActivationDate')}:{' '}
          <span className='font-normal'>{activationDate.split('T')[0]}</span>
        </p>
      )}
      {endDate && endDate.length > 0 && (
        <p className='mb-2 text-sm font-bold'>
          {t('label.endDate')}:{' '}
          <span className='font-normal'>{endDate.split('T')[0]}</span>
        </p>
      )}
    </div>
  );
};

export { OfferDetails };
