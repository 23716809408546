import invariant from 'tiny-invariant';
import { ServiceData, ServiceQuery } from '../service';

import { customerAdapter } from '@adapters/customer';

import { OffersResponse } from '@models/ads';
import { Transaction } from '@models/campaign';
// import { Offer } from '@models/campaign';

interface GetByIdData extends ServiceData {
  // offers: Offer[];
  offers: any;
}

interface GetByIdParams {
  sourceCustomerId: string;
}

const parseOffersResponse = (offers: OffersResponse) => {
  if (offers.ads === null) {
    // Customer with no offers assigned
    return [];
  }

  const { ads } = offers;

  const parsedOffers = Object.keys(ads).map((key) => ({
    id: key,
    ...ads[key]
  }));

  const parsedAds = parsedOffers.map((ad) => {
    const {
      id,
      merchantName,
      startDate,
      endDate,
      reward,
      assets,
      activationState,
      redemptionState,
      visibilityState,
      transactions
    } = ad;

    const {
      purchaseRequirement,
      rewardAmount,
      rewardType, // Ask fabiano and yagnik
      isMultiRedemption,
      maxRewardAmount
    } = reward;

    const { purchaseChannels } = purchaseRequirement;

    const { logo, copy } = assets;
    const logoUrl = logo.value.small || logo.value.large;

    const rewardDetails = copy.value.preMessage;

    const rewardPercent =
      rewardType === 'PERCENT_AMOUNT_PURCHASE'
        ? Math.round(rewardAmount * 100) / 100
        : 0;

    const rewardAmountFixed =
      rewardType === 'FIXED_AMOUNT_PURCHASE'
        ? (Math.round(rewardAmount * 100) / 100).toFixed(2)
        : 0;

    return {
      merchantName,
      startDate,
      endDate,
      adId: id,
      purchaseChannels,
      logo: logoUrl,
      rewardDetails,
      rewardAmount: rewardAmountFixed,
      rewardPercent,
      isMultiRedemption,
      maxRewardAmount,
      activationState,
      redemptionState,
      visibilityState,
      originalOffer: ad,
      transactions:
        transactions && transactions.length > 0
          ? transactions
          : ([] as Transaction[])
    };
  });

  return parsedAds;
};

const getOffersById: ServiceQuery<GetByIdData, GetByIdParams> = async (
  context
) => {
  const { queryKey, signal } = context;
  const [{ sourceCustomerId }] = queryKey;

  invariant(sourceCustomerId, 'sourceCustomerId is required');

  const { data } = await customerAdapter.getOffersById(
    { sourceCustomerId },
    { signal }
  );

  return { offers: parseOffersResponse(data) };
};

export type { GetByIdData, GetByIdParams };
export { getOffersById };
